/* eslint-disable @typescript-eslint/no-explicit-any */
import "./designed-icon.module.css";

import { motion } from "framer-motion";
import { useMemo } from "react";
import { Box } from "simple-effing-primitive-layout";

import _Collections from "./assets/_collections.svg";
import _Download from "./assets/_download.svg";
import _Editor from "./assets/_editor.svg";
import _Guides from "./assets/_guides.svg";
import _Ovation from "./assets/_ovation.svg";
import _ProLicenseFilled from "./assets/_pro_license_filled.svg";
import _ProLicenseOutlined from "./assets/_pro_license_outlined.svg";
import _Ticket from "./assets/_ticket.svg";
import _IconFacebook from "./assets/@facebook.svg";
import _IconInstagram from "./assets/@instagram.svg";
import _IconLinkedin from "./assets/@linkedin.svg";
import _IconPaperclip from "./assets/@paperclip.svg";
import _IconTwitter from "./assets/@twitter.svg";
import _IconYoutube from "./assets/@youtube.svg";
import IconAddToCart from "./assets/add_to_cart.svg";
import IconAddedToCart from "./assets/added_to_cart.svg";
import IconAlignmentBold from "./assets/alignment_bold.svg";
import IconAlignmentFilled from "./assets/alignment_filled.svg";
import IconAlignmentRegular from "./assets/alignment_regular.svg";
import IconBoldBold from "./assets/bold_bold.svg";
import IconBoldFilled from "./assets/bold_filled.svg";
import IconBoldRegular from "./assets/bold_regular.svg";
import IconBookText from "./assets/book_text.svg";
import IconBookmarkBold from "./assets/bookmark_bold.svg";
import IconBookmarkFilled from "./assets/bookmark_filled.svg";
import IconBookmarkRegular from "./assets/bookmark_regular.svg";
import IconCardBold from "./assets/card_bold.svg";
import IconCardFilled from "./assets/card_filled.svg";
import IconCardRegular from "./assets/card_regular.svg";
import IconCartBold from "./assets/cart_bold.svg";
import IconCartFilled from "./assets/cart_filled.svg";
import IconCartRegular from "./assets/cart_regular.svg";
import IconCheckBold from "./assets/check_bold.svg";
import IconCheckFilled from "./assets/check_filled.svg";
import IconCheckRegular from "./assets/check_regular.svg";
import IconClose from "./assets/close.svg";
import IconCommentsBold from "./assets/comments_bold.svg";
import IconCommentsFilled from "./assets/comments_filled.svg";
import IconCommentsRegular from "./assets/comments_regular.svg";
import IconDeleteBold from "./assets/delete_bold.svg";
import IconDeleteFilled from "./assets/delete_filled.svg";
import IconDeleteRegular from "./assets/delete_regular.svg";
import IconDisableBold from "./assets/disable_bold.svg";
import IconDisableFilled from "./assets/disable_filled.svg";
import IconDisableRegular from "./assets/disable_regular.svg";
import IconDiscountRegular from "./assets/discount_regular.svg";
import IconDocumentBold from "./assets/document_bold.svg";
import IconDocumentFilled from "./assets/document_filled.svg";
import IconDocumentRegular from "./assets/document_regular.svg";
import IconDownBold from "./assets/down_bold.svg";
import IconDownFilled from "./assets/down_filled.svg";
import IconDownRegular from "./assets/down_regular.svg";
import IconDrawingBold from "./assets/drawing_bold.svg";
import IconDrawingFilled from "./assets/drawing_filled.svg";
import IconDrawingRegular from "./assets/drawing_regular.svg";
import IconEditRegular from "./assets/edit.svg";
import IconEqualsBold from "./assets/equals_bold.svg";
import IconEqualsFilled from "./assets/equals_filled.svg";
import IconEqualsRegular from "./assets/equals_regular.svg";
import IconExternalBold from "./assets/external_bold.svg";
import IconExternalFilled from "./assets/external_filled.svg";
import IconExternalRegular from "./assets/external_regular.svg";
import IconFileQuestionRegular from "./assets/filequestion_regular.svg";
import IconFilterBold from "./assets/filter_bold.svg";
import IconFilterFilled from "./assets/filter_filled.svg";
import IconFilterRegular from "./assets/filter_regular.svg";
import IconFontBold from "./assets/font_bold.svg";
import IconFontFilled from "./assets/font_filled.svg";
import IconFontRegular from "./assets/font_regular.svg";
import IconHomeBold from "./assets/home_bold.svg";
import IconHomeFilled from "./assets/home_filled.svg";
import IconHomeRegular from "./assets/home_regular.svg";
import IconInBold from "./assets/in_bold.svg";
import IconInFilled from "./assets/in_filled.svg";
import IconInRegular from "./assets/in_regular.svg";
import IconInfoBold from "./assets/info_bold.svg";
import IconInfoFilled from "./assets/info_filled.svg";
import IconInfoRegular from "./assets/info_regular.svg";
import IconItalicBold from "./assets/italic_bold.svg";
import IconItalicFilled from "./assets/italic_filled.svg";
import IconItalicRegular from "./assets/italic_regular.svg";
import IconLargeBold from "./assets/large_bold.svg";
import IconLargeFilled from "./assets/large_filled.svg";
import IconLargeRegular from "./assets/large_regular.svg";
import IconLeftBold from "./assets/left_bold.svg";
import IconLeftFilled from "./assets/left_filled.svg";
import IconLeftRegular from "./assets/left_regular.svg";
import IconListRegular from "./assets/list_regular.svg";
import IconMarkerBold from "./assets/marker_bold.svg";
import IconMarkerFilled from "./assets/marker_filled.svg";
import IconMarkerRegular from "./assets/marker_regular.svg";
import IconMegaphoneBold from "./assets/megaphone_bold.svg";
import IconMegaphoneFilled from "./assets/megaphone_filled.svg";
import IconMegaphoneRegular from "./assets/megaphone_regular.svg";
import IconMenuBold from "./assets/menu_bold.svg";
import IconMenuFilled from "./assets/menu_filled.svg";
import IconMenuRegular from "./assets/menu_regular.svg";
import IconMinusBold from "./assets/minus_bold.svg";
import IconMinusFilled from "./assets/minus_filled.svg";
import IconMinusRegular from "./assets/minus_regular.svg";
import IconMoonBold from "./assets/moon_bold.svg";
import IconMoonFilled from "./assets/moon_filled.svg";
import IconMoonRegular from "./assets/moon_regular.svg";
import IconMoreBold from "./assets/more_bold.svg";
import IconMoreFilled from "./assets/more_filled.svg";
import IconMoreRegular from "./assets/more_regular.svg";
import IconMouseBold from "./assets/mouse_bold.svg";
import IconMouseFilled from "./assets/mouse_filled.svg";
import IconMouseRegular from "./assets/mouse_regular.svg";
import IconMultiplyBold from "./assets/multiply_bold.svg";
import IconMultiplyFilled from "./assets/multiply_filled.svg";
import IconMultiplyRegular from "./assets/multiply_regular.svg";
import IconOpenBold from "./assets/open_bold.svg";
import IconOpenFilled from "./assets/open_filled.svg";
import IconOpenRegular from "./assets/open_regular.svg";
import IconOutBold from "./assets/out_bold.svg";
import IconOutFilled from "./assets/out_filled.svg";
import IconOutRegular from "./assets/out_regular.svg";
import IconPanelBold from "./assets/panel_bold.svg";
import IconPanelFilled from "./assets/panel_filled.svg";
import IconPanelRegular from "./assets/panel_regular.svg";
import IconPanellesBold from "./assets/panelles_bold.svg";
import IconPanellesFilled from "./assets/panelles_filled.svg";
import IconPanellesRegular from "./assets/panelles_regular.svg";
import IconPauseBold from "./assets/pause_bold.svg";
import IconPauseFilled from "./assets/pause_filled.svg";
import IconPauseRegular from "./assets/pause_regular.svg";
import IconPenBold from "./assets/pen_bold.svg";
import IconPenFilled from "./assets/pen_filled.svg";
import IconPenRegular from "./assets/pen_regular.svg";
import IconPencilBold from "./assets/pencil_bold.svg";
import IconPencilFilled from "./assets/pencil_filled.svg";
import IconPencilRegular from "./assets/pencil_regular.svg";
import IconPictureBold from "./assets/picture_bold.svg";
import IconPictureFilled from "./assets/picture_filled.svg";
import IconPictureRegular from "./assets/picture_regular.svg";
import IconPlayBold from "./assets/play_bold.svg";
import IconPlayFilled from "./assets/play_filled.svg";
import IconPlayRegular from "./assets/play_regular.svg";
import IconPlusBold from "./assets/plus_bold.svg";
import IconPlusFilled from "./assets/plus_filled.svg";
import IconPlusRegular from "./assets/plus_regular.svg";
import IconRightBold from "./assets/right_bold.svg";
import IconRightFilled from "./assets/right_filled.svg";
import IconRightRegular from "./assets/right_regular.svg";
import IconSearchBold from "./assets/search_bold.svg";
import IconSearchFilled from "./assets/search_filled.svg";
import IconSearchRegular from "./assets/search_regular.svg";
import IconShareBold from "./assets/share_bold.svg";
import IconShareFilled from "./assets/share_filled.svg";
import IconShareRegular from "./assets/share_regular.svg";
import IconShoppingBag from "./assets/shopping_bag.svg";
import IconSmallBold from "./assets/small_bold.svg";
import IconSmallFilled from "./assets/small_filled.svg";
import IconSmallRegular from "./assets/small_regular.svg";
import IconStartBold from "./assets/start_bold.svg";
import IconStartFilled from "./assets/start_filled.svg";
import IconStartRegular from "./assets/start_regular.svg";
import IconSunBold from "./assets/sun_bold.svg";
import IconSunFilled from "./assets/sun_filled.svg";
import IconSunRegular from "./assets/sun_regular.svg";
import IconUpBold from "./assets/up_bold.svg";
import IconUpFilled from "./assets/up_filled.svg";
import IconUpRegular from "./assets/up_regular.svg";
import IconUserBold from "./assets/user_bold.svg";
import IconUserFilled from "./assets/user_filled.svg";
import IconUserRegular from "./assets/user_regular.svg";
import IconUsersRegular from "./assets/users.svg";
import IconTick from "./assets/tick.svg";
import IconDate from "./assets/date.svg";
import IconCircleArrowRight from "./assets/circle_arrow_right.svg";
import IconUpCaret from "./assets/up_caret.svg";
import IconDownCaret from "./assets/down_caret.svg";
import IconClock from "./assets/clock.svg";
import IconEarth from "./assets/earth.svg";
import IconShowMask from "./assets/show_mask.svg";

type IconGroup = {
  regular: string;
  bold: string;
  filled: string;
};

type IconGroupDictionary = {
  [key: string]: IconGroup;
};

type IconWeight = "regular" | "bold" | "filled";

interface DesignedIconProps {
  name: AvailableIconName;
  weight?: IconWeight;
  size?: number;
  press?: () => void;
  color?: string;
  override?: string;
  properties?: { [key: string]: unknown };
}

export type AvailableIconName =
  | "_ovation"
  | "_guides"
  | "_editor"
  | "_open"
  | "_collections"
  | "_ticket"
  | "@facebook"
  | "@twitter"
  | "@instagram"
  | "@linkedin"
  | "@youtube"
  | "@paperclip"
  | "alignment"
  | "bold"
  | "bookmark"
  | "card"
  | "cart"
  | "check"
  | "comments"
  | "delete"
  | "disable"
  | "discount"
  | "document"
  | "down"
  | "drawing"
  | "equals"
  | "external"
  | "filequestion"
  | "filter"
  | "font"
  | "home"
  | "in"
  | "info"
  | "italic"
  | "large"
  | "left"
  | "list"
  | "marker"
  | "megaphone"
  | "menu"
  | "minus"
  | "moon"
  | "more"
  | "mouse"
  | "multiply"
  | "open"
  | "out"
  | "panel"
  | "panelles"
  | "pause"
  | "pen"
  | "pencil"
  | "picture"
  | "play"
  | "plus"
  | "right"
  | "search"
  | "share"
  | "small"
  | "start"
  | "sun"
  | "up"
  | "user"
  | "alignment/regular"
  | "bold/regular"
  | "bookmark/regular"
  | "card/regular"
  | "cart/regular"
  | "check/regular"
  | "comments/regular"
  | "delete/regular"
  | "disable/regular"
  | "discount/regular"
  | "document/regular"
  | "down/regular"
  | "drawing/regular"
  | "equals/regular"
  | "external/regular"
  | "filequestion/regular"
  | "filter/regular"
  | "font/regular"
  | "home/regular"
  | "in/regular"
  | "info/regular"
  | "italic/regular"
  | "large/regular"
  | "left/regular"
  | "list/regular"
  | "marker/regular"
  | "megaphone/regular"
  | "menu/regular"
  | "minus/regular"
  | "moon/regular"
  | "more/regular"
  | "mouse/regular"
  | "multiply/regular"
  | "open/regular"
  | "out/regular"
  | "panel/regular"
  | "panelles/regular"
  | "pause/regular"
  | "pen/regular"
  | "pencil/regular"
  | "picture/regular"
  | "play/regular"
  | "plus/regular"
  | "right/regular"
  | "search/regular"
  | "share/regular"
  | "small/regular"
  | "start/regular"
  | "sun/regular"
  | "up/regular"
  | "user/regular"
  | "alignment/bold"
  | "bold/bold"
  | "bookmark/bold"
  | "card/bold"
  | "cart/bold"
  | "check/bold"
  | "comments/bold"
  | "delete/bold"
  | "disable/bold"
  | "document/bold"
  | "down/bold"
  | "drawing/bold"
  | "equals/bold"
  | "external/bold"
  | "filter/bold"
  | "font/bold"
  | "home/bold"
  | "in/bold"
  | "info/bold"
  | "italic/bold"
  | "large/bold"
  | "left/bold"
  | "marker/bold"
  | "megaphone/bold"
  | "menu/bold"
  | "minus/bold"
  | "moon/bold"
  | "more/bold"
  | "mouse/bold"
  | "multiply/bold"
  | "open/bold"
  | "out/bold"
  | "panel/bold"
  | "panelles/bold"
  | "pause/bold"
  | "pen/bold"
  | "pencil/bold"
  | "picture/bold"
  | "play/bold"
  | "plus/bold"
  | "right/bold"
  | "search/bold"
  | "share/bold"
  | "small/bold"
  | "start/bold"
  | "sun/bold"
  | "up/bold"
  | "user/bold"
  | "alignment/filled"
  | "bold/filled"
  | "bookmark/filled"
  | "card/filled"
  | "cart/filled"
  | "check/filled"
  | "comments/filled"
  | "delete/filled"
  | "disable/filled"
  | "document/filled"
  | "down/filled"
  | "drawing/filled"
  | "equals/filled"
  | "external/filled"
  | "filter/filled"
  | "font/filled"
  | "home/filled"
  | "in/filled"
  | "info/filled"
  | "italic/filled"
  | "large/filled"
  | "left/filled"
  | "marker/filled"
  | "megaphone/filled"
  | "menu/filled"
  | "minus/filled"
  | "moon/filled"
  | "more/filled"
  | "mouse/filled"
  | "multiply/filled"
  | "open/filled"
  | "out/filled"
  | "panel/filled"
  | "panelles/filled"
  | "pause/filled"
  | "pen/filled"
  | "pencil/filled"
  | "picture/filled"
  | "play/filled"
  | "plus/filled"
  | "right/filled"
  | "search/filled"
  | "share/filled"
  | "small/filled"
  | "start/filled"
  | "sun/filled"
  | "up/filled"
  | "user/filled"
  | "_proLicense/filled"
  | "_proLicense/outlined"
  | "users"
  | "edit"
  | "book_text"
  | "shopping_bag"
  | "add_to_cart"
  | "added_to_cart"
  | "close"
  | "tick"
  | "date"
  | "circle_arrow_right"
  | "up_caret"
  | "down_caret"
  | "clock"
  | "earth"
  | "show_mask";

const DICTIONARY: IconGroupDictionary = {
  _collections: {
    regular: _Collections,
    bold: _Collections,
    filled: _Collections,
  },
  _editor: {
    regular: _Editor,
    bold: _Editor,
    filled: _Editor,
  },
  _guides: {
    regular: _Guides,
    bold: _Guides,
    filled: _Guides,
  },
  _ovation: {
    regular: _Ovation,
    bold: _Ovation,
    filled: _Ovation,
  },
  _download: {
    regular: _Download,
    bold: _Download,
    filled: _Download,
  },
  _proLicense: {
    regular: _ProLicenseOutlined,
    bold: _ProLicenseFilled,
    filled: _ProLicenseFilled,
  },
  _ticket: {
    regular: _Ticket,
    bold: _Ticket,
    filled: _Ticket,
  },
  alignment: {
    regular: IconAlignmentRegular,
    bold: IconAlignmentBold,
    filled: IconAlignmentFilled,
  },
  bold: {
    regular: IconBoldRegular,
    bold: IconBoldBold,
    filled: IconBoldFilled,
  },
  bookmark: {
    regular: IconBookmarkRegular,
    bold: IconBookmarkBold,
    filled: IconBookmarkFilled,
  },
  card: {
    regular: IconCardRegular,
    bold: IconCardBold,
    filled: IconCardFilled,
  },
  cart: {
    regular: IconCartRegular,
    bold: IconCartBold,
    filled: IconCartFilled,
  },
  check: {
    regular: IconCheckRegular,
    bold: IconCheckBold,
    filled: IconCheckFilled,
  },
  comments: {
    regular: IconCommentsRegular,
    bold: IconCommentsBold,
    filled: IconCommentsFilled,
  },
  delete: {
    regular: IconDeleteRegular,
    bold: IconDeleteBold,
    filled: IconDeleteFilled,
  },
  disable: {
    regular: IconDisableRegular,
    bold: IconDisableBold,
    filled: IconDisableFilled,
  },
  discount: {
    regular: IconDiscountRegular,
    bold: IconDiscountRegular,
    filled: IconDiscountRegular,
  },
  document: {
    regular: IconDocumentRegular,
    bold: IconDocumentBold,
    filled: IconDocumentFilled,
  },
  down: {
    regular: IconDownRegular,
    bold: IconDownBold,
    filled: IconDownFilled,
  },
  drawing: {
    regular: IconDrawingRegular,
    bold: IconDrawingBold,
    filled: IconDrawingFilled,
  },
  equals: {
    regular: IconEqualsRegular,
    bold: IconEqualsBold,
    filled: IconEqualsFilled,
  },
  external: {
    regular: IconExternalRegular,
    bold: IconExternalBold,
    filled: IconExternalFilled,
  },
  filequestion: {
    regular: IconFileQuestionRegular,
    bold: IconFileQuestionRegular,
    filled: IconFileQuestionRegular,
  },
  filter: {
    regular: IconFilterRegular,
    bold: IconFilterBold,
    filled: IconFilterFilled,
  },
  font: {
    regular: IconFontRegular,
    bold: IconFontBold,
    filled: IconFontFilled,
  },
  home: {
    regular: IconHomeRegular,
    bold: IconHomeBold,
    filled: IconHomeFilled,
  },
  in: {
    regular: IconInRegular,
    bold: IconInBold,
    filled: IconInFilled,
  },
  info: {
    regular: IconInfoRegular,
    bold: IconInfoBold,
    filled: IconInfoFilled,
  },
  italic: {
    regular: IconItalicRegular,
    bold: IconItalicBold,
    filled: IconItalicFilled,
  },
  large: {
    regular: IconLargeRegular,
    bold: IconLargeBold,
    filled: IconLargeFilled,
  },
  left: {
    regular: IconLeftRegular,
    bold: IconLeftBold,
    filled: IconLeftFilled,
  },
  list: {
    regular: IconListRegular,
    bold: IconListRegular,
    filled: IconListRegular,
  },
  marker: {
    regular: IconMarkerRegular,
    bold: IconMarkerBold,
    filled: IconMarkerFilled,
  },
  megaphone: {
    regular: IconMegaphoneRegular,
    bold: IconMegaphoneBold,
    filled: IconMegaphoneFilled,
  },
  menu: {
    regular: IconMenuRegular,
    bold: IconMenuBold,
    filled: IconMenuFilled,
  },
  minus: {
    regular: IconMinusRegular,
    bold: IconMinusBold,
    filled: IconMinusFilled,
  },
  moon: {
    regular: IconMoonRegular,
    bold: IconMoonBold,
    filled: IconMoonFilled,
  },
  more: {
    regular: IconMoreRegular,
    bold: IconMoreBold,
    filled: IconMoreFilled,
  },
  mouse: {
    regular: IconMouseRegular,
    bold: IconMouseBold,
    filled: IconMouseFilled,
  },
  multiply: {
    regular: IconMultiplyRegular,
    bold: IconMultiplyBold,
    filled: IconMultiplyFilled,
  },
  open: {
    regular: IconOpenRegular,
    bold: IconOpenBold,
    filled: IconOpenFilled,
  },
  out: {
    regular: IconOutRegular,
    bold: IconOutBold,
    filled: IconOutFilled,
  },
  panel: {
    regular: IconPanelRegular,
    bold: IconPanelBold,
    filled: IconPanelFilled,
  },
  panelles: {
    regular: IconPanellesRegular,
    bold: IconPanellesBold,
    filled: IconPanellesFilled,
  },
  pause: {
    regular: IconPauseRegular,
    bold: IconPauseBold,
    filled: IconPauseFilled,
  },
  pen: {
    regular: IconPenRegular,
    bold: IconPenBold,
    filled: IconPenFilled,
  },
  pencil: {
    regular: IconPencilRegular,
    bold: IconPencilBold,
    filled: IconPencilFilled,
  },
  picture: {
    regular: IconPictureRegular,
    bold: IconPictureBold,
    filled: IconPictureFilled,
  },
  play: {
    regular: IconPlayRegular,
    bold: IconPlayBold,
    filled: IconPlayFilled,
  },
  plus: {
    regular: IconPlusRegular,
    bold: IconPlusBold,
    filled: IconPlusFilled,
  },
  right: {
    regular: IconRightRegular,
    bold: IconRightBold,
    filled: IconRightFilled,
  },
  search: {
    regular: IconSearchRegular,
    bold: IconSearchBold,
    filled: IconSearchFilled,
  },
  share: {
    regular: IconShareRegular,
    bold: IconShareBold,
    filled: IconShareFilled,
  },
  small: {
    regular: IconSmallRegular,
    bold: IconSmallBold,
    filled: IconSmallFilled,
  },
  start: {
    regular: IconStartRegular,
    bold: IconStartBold,
    filled: IconStartFilled,
  },
  sun: {
    regular: IconSunRegular,
    bold: IconSunBold,
    filled: IconSunFilled,
  },
  up: {
    regular: IconUpRegular,
    bold: IconUpBold,
    filled: IconUpFilled,
  },
  user: {
    regular: IconUserRegular,
    bold: IconUserBold,
    filled: IconUserFilled,
  },
  users: {
    regular: IconUsersRegular,
    bold: IconUsersRegular,
    filled: IconUsersRegular,
  },
  edit: {
    regular: IconEditRegular,
    bold: IconEditRegular,
    filled: IconEditRegular,
  },
  book_text: {
    regular: IconBookText,
    bold: IconBookText,
    filled: IconBookText,
  },
  shopping_bag: {
    regular: IconShoppingBag,
    bold: IconShoppingBag,
    filled: IconShoppingBag,
  },
  add_to_cart: {
    regular: IconAddToCart,
    bold: IconAddToCart,
    filled: IconAddToCart,
  },
  added_to_cart: {
    regular: IconAddedToCart,
    bold: IconAddedToCart,
    filled: IconAddedToCart,
  },
  close: {
    regular: IconClose,
    bold: IconClose,
    filled: IconClose,
  },
  tick: {
    regular: IconTick,
    bold: IconTick,
    filled: IconTick,
  },
  date: {
    regular: IconDate,
    bold: IconDate,
    filled: IconDate,
  },
  circle_arrow_right: {
    regular: IconCircleArrowRight,
    bold: IconCircleArrowRight,
    filled: IconCircleArrowRight,
  },
  up_caret: {
    regular: IconUpCaret,
    bold: IconUpCaret,
    filled: IconUpCaret,
  },
  down_caret: {
    regular: IconDownCaret,
    bold: IconDownCaret,
    filled: IconDownCaret,
  },
  clock: {
    regular: IconClock,
    bold: IconClock,
    filled: IconClock,
  },
  earth: {
    regular: IconEarth,
    bold: IconEarth,
    filled: IconEarth,
  },
  show_mask: {
    regular: IconShowMask,
    bold: IconShowMask,
    filled: IconShowMask,
  },
};

type IconTypeString = string;
type IconTypeNext = { src: string; width: number; height: number };

export const DesignedIcon = ({
  name,
  weight = "regular",
  size = 24,
  press,
  color = "#59687B",
  override,
  properties,
}: DesignedIconProps): JSX.Element => {
  const icon: IconTypeString | IconTypeNext | null = useMemo(() => {
    if (name.includes("@")) {
      switch (name) {
        case "@facebook":
          return _IconFacebook;

        case "@instagram":
          return _IconInstagram;

        case "@linkedin":
          return _IconLinkedin;

        case "@twitter":
          return _IconTwitter;

        case "@youtube":
          return _IconYoutube;

        case "@paperclip":
          return _IconPaperclip;

        default:
          return null;
      }
    } else {
      if (name.includes("/")) {
        const _name = name.split("/")[0];
        const _weight = name.split("/")[1] as IconWeight;

        if (DICTIONARY[_name] !== undefined) {
          if (DICTIONARY[_name][_weight] !== undefined) {
            return DICTIONARY[_name][_weight];
          } else {
            const _first = (
              Object.entries(DICTIONARY[_name]) as [IconWeight, string][]
            )[0][0];
            return DICTIONARY[_name][_first];
          }
        } else {
          return null;
        }
      } else {
        if (DICTIONARY[name] !== undefined) {
          if (DICTIONARY[name][weight] !== undefined) {
            return DICTIONARY[name][weight];
          } else {
            const _first = (
              Object.entries(DICTIONARY[name]) as [IconWeight, string][]
            )[0][0];
            return DICTIONARY[name][_first];
          }
        } else {
          return null;
        }
      }
    }
  }, [name, weight]);

  const styles: { [key: string]: unknown } = useMemo(() => {
    if (icon === null) {
      return {};
    }

    const _src =
      typeof icon === "string"
        ? icon
        : (icon as IconTypeNext).src !== undefined
        ? (icon as IconTypeNext).src
        : "";

    if (name.includes("@") && !override) {
      return {
        backgroundImage: "url(" + _src + ")",
        cursor: press ? "pointer" : undefined,
        backgroundSize: "cover",
      };
    } else {
      return {
        backgroundColor: override || color,
        mask: "url(" + _src + ")",
        WebkitMask: "url(" + _src + ")",
      };
    }
  }, [icon, size, color]);

  return (
    <IconWrapper properties={properties} press={press ? press : undefined}>
      {icon === null ? (
        <Box
          press={press ? press : undefined}
          parse={`br:2.5 w:${size} h:${size}`}
          color={color}
        />
      ) : typeof icon === "string" ? (
        <span
          onClick={press ? press : undefined}
          className="--icon"
          style={{
            ...styles,
            width: size,
            height: size,
            cursor: press ? "pointer" : undefined,
          }}
        />
      ) : (icon as IconTypeNext).src !== undefined ? (
        <span
          onClick={press ? press : undefined}
          className="--icon"
          style={{
            ...styles,
            width: size,
            height: size,
            cursor: press ? "pointer" : undefined,
          }}
        />
      ) : (
        <span />
      )}
    </IconWrapper>
  );
};

const IconWrapper = ({
  press,
  children,
  properties,
}: {
  press?: () => void;
  children: any;
  properties?: { [key: string]: unknown };
}) => {
  return press ? (
    <motion.div
      {...properties}
      whileHover={{ scale: 1.025, opacity: 0.85 }}
      whileTap={{ scale: 0.95, opacity: 1 }}
      transition={{
        type: "spring",
        damping: 10,
        stiffness: 100,
      }}
      style={{ display: "inline-flex" }}
    >
      {children}
    </motion.div>
  ) : (
    children
  );
};

export default DesignedIcon;
