import { useAuth } from "@with-nx/auth";
import { Caching, ThrottlerController } from "@with-nx/hooks-n-helpers";
import moment from "moment";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ShoppingCartHelpers } from "../cart";
import ShoppingCartMethods, {
  ShoppingCartMethodProductType,
} from "../cart/ShoppingCartMethods";
import ShoppingCartUtils from "../cart/ShoppingCartUtils";
import { AppDispatch, RootState } from "../store/";
import * as Slice from "../store/slice";
import { Ecommerce } from "../types/types";

import Toast from "react-hot-toast";

export const useShoppingCart = () => {
  const dispatch = useDispatch<AppDispatch>();
  const data = useSelector((state: RootState) => state.cart);
  const quantity =
    Number(data.data?.licensedItems?.length || 0) +
    Number(data.data?.nonLicensedItems?.length || 0);
  const step = data.step;

  const auth = useAuth();
  const token = auth?.authState?.session?.token as string;

  const reload = async () => {
    if (!token) {
      return;
    }

    await dispatch(Slice.fetch({ token }));
    await methods.populate.addresses();
    await methods.populate.productions();
    await methods.populate.stageGear();

    dispatch(Slice.populate(true));
  };

  const methods = {
    cart: {
      get: async () => {
        return await ShoppingCartMethods.getCurrent({ token });
      },
      reload: () => {
        reload();
      },
      clear: () => {
        dispatch(Slice.clear());
      },
      registerProposalView: async (proposalId: string) => {
        try {
          const timeout = 3_600_000 * 24;
          const last: string | null | undefined = Caching.get(
            `view:${proposalId}`,
            timeout
          );

          if (!last) {
            Caching.set(
              `view:${proposalId}`,
              moment().format("YYYY-MM-DD"),
              timeout
            );
          }

          if (typeof last === "string") {
            if (moment().format("YYYY-MM-DD") === last) {
              return;
            }
          }

          ShoppingCartMethods.registerProposalView({ proposalId, token });
        } catch (error) {
          console.error(error);
        }
      },
    },
    step: {
      set: (step: number) => {
        dispatch(Slice.step(step));
      },
    },
    product: {
      add: async ({
        options,
        id,
        type,
      }: {
        token: string;
        options?: {
          proLicense?: boolean;
          rentalStart?: string;
          rentalEnd?: string;
        };
        id: number | string;
        type: ShoppingCartMethodProductType;
      }) => {
        if (
          utils.exists({
            id,
          }) &&
          type !== "STAGE_GEAR"
        ) {
          const lineItemId = utils.getLineItemId(id);
          await ShoppingCartMethods.removeProduct({ token, id: lineItemId });
        }

        dispatch(
          Slice.set(
            await ShoppingCartMethods.addProduct({ token, id, options, type })
          )
        );
      },
      remove: async ({ id }: { id: number | string }) => {
        dispatch(
          Slice.set(await ShoppingCartMethods.removeProduct({ token, id }))
        );
      },
    },
    productions: {
      get: async () => {
        return await ShoppingCartMethods.getProductions({ token });
      },
      create: async ({
        production,
        all,
      }: {
        production: Ecommerce.Schemas.ProductionSchemaType;
        all?: boolean;
      }) => {
        const data = await ShoppingCartMethods.createProduction({
          token,
          production,
          all,
        });

        Slice.set(data);
        reload();
        return data;
      },
      update: async ({
        production,
      }: {
        production: Ecommerce.Schemas.ProductionSchemaType;
      }) => {
        const data = await ShoppingCartMethods.createProduction({
          token,
          production,
        });

        Slice.set(data);
        return data;
      },
      assign: async ({
        production,
        id,
      }: {
        production: number;
        id: number | string;
      }) => {
        await ShoppingCartMethods.setProductionToItem({
          token,
          production,
          id,
        });
        reload();
      },
      delete: async ({ production }: { production: number | string }) => {
        await ShoppingCartMethods.deleteProduction({
          token,
          production,
        });
        reload();
      },
    },
    address: {
      set: async ({
        address,
        type,
      }: {
        address: string | number;
        type: "BOTH" | "BILLING" | "SHIPPING";
      }) => {
        const data = await ShoppingCartMethods.setAddress({
          token,
          address,
          type,
        });
        reload();
        return data;
      },
      create: async ({
        address,
      }: {
        address: Ecommerce.Payload.Address.CreateAddress;
      }) => {
        return await ShoppingCartMethods.createAddress({
          token,
          address,
        });
      },
      update: async ({
        address,
        id,
      }: {
        address: Ecommerce.Payload.Address.CreateAddress;
        id: number | string;
      }) => {
        await ShoppingCartMethods.updateAddress({
          token,
          address,
          id,
        });
      },
      all: async () => {
        return await ShoppingCartMethods.getAllAddresses({
          token,
        });
      },
    },
    mark: {
      download: async () => {
        return await ShoppingCartMethods.markInvoiceAsDownloaded({ token });
      },
    },
    discount: {
      promotional: {
        apply: async (code: string) => {
          dispatch(
            Slice.set(
              await ShoppingCartMethods.applyPromotionalDiscount({
                token,
                code,
              })
            )
          );
        },
        remove: async ({ code }: { code: string }) => {
          dispatch(
            Slice.set(
              await ShoppingCartMethods.removePromotionalDiscount({
                token,
                code,
              })
            )
          );
        },
      },
    },
    populate: {
      productions: (bypass = false) => {
        if (data?.productions?.length > 0 && !bypass) {
          return;
        }

        data.data?.licensedItems?.map((item) => {
          const production: Ecommerce.Payload.Production.CreateProduction = {
            ...item.production,
            line: item.id || 0,
            licensorCode: item.production?.licensorCode || null,
            additionalWeeks: item.production?.additionalWeeks || 0,
            organizationName:
              item.production?.organizationName ||
              auth?.authState?.session?.user?.organization,
          };

          helpers.production.set({
            type: "licensed",
            production,
            meta: {
              organization: auth?.authState?.session?.user?.organization,
            },
          });
        });

        data.data?.nonLicensedItems?.map((item) => {
          const production: Ecommerce.Payload.Production.CreateProduction = {
            ...item.production,
            line: item.id || 0,
            licensorCode: item.production?.licensorCode || null,
            additionalWeeks: item.production?.additionalWeeks || 0,
            organizationName:
              item.production?.organizationName ||
              auth?.authState?.session?.user?.organization,
          };

          helpers.production.set({
            type: "non-licensed",
            production,
            meta: {
              organization: auth?.authState?.session?.user?.organization,
            },
          });
        });
      },
      addresses: async (bypass = false) => {
        if (data.addresses?.billing && data.addresses?.shipping && !bypass) {
          return;
        }

        if (auth.authState.status !== "authenticated") {
          return;
        }

        const addresses = await ShoppingCartMethods.getAllAddresses({
          token,
        });

        const _shipping =
          addresses?.find((address) => address.defaultShipping) ||
          addresses?.at(-1);
        const shipping: Ecommerce.Payload.Address.CreateAddress = {
          ..._shipping,
          line1: _shipping?.line1 || "",
          postalCode: _shipping?.postalCode || "",
          location: {
            country: {
              ..._shipping?.location?.country,
              code: _shipping?.location?.country?.code || "US",
              name: _shipping?.location?.country?.name || "United States",
            },
            city: {
              ..._shipping?.location?.city,
              id: _shipping?.location?.city?.id || -1,
              name: _shipping?.location?.city?.name || "",
              stateCode:
                _shipping?.location?.state?.code ||
                _shipping?.location?.city?.stateCode ||
                _shipping?.location?.city?.state_code ||
                "-1",
            },
            state: {
              ..._shipping?.location?.state,
              code: _shipping?.location?.state?.code || "-1",
              name: _shipping?.location?.state?.name || "",
              countryCode:
                _shipping?.location?.state?.countryCode ||
                _shipping?.location?.state?.country_code ||
                "US",
            },
          },
        };

        const _billing =
          addresses?.find((address) => address.defaultBilling) ||
          addresses?.at(-1);

        const billing: Ecommerce.Payload.Address.CreateAddress = {
          ..._billing,
          line1: _billing?.line1 || "",
          postalCode: _billing?.postalCode || "",
          location: {
            country: {
              ..._billing?.location?.country,
              code: _billing?.location?.country?.code || "US",
              name: _billing?.location?.country?.name || "United States",
            },
            city: {
              ..._billing?.location?.city,
              id: _billing?.location?.city?.id || -1,
              name: _billing?.location?.city?.name || "",
              stateCode:
                _billing?.location?.state?.code ||
                _billing?.location?.city?.stateCode ||
                _billing?.location?.city?.state_code ||
                "-1",
            },
            state: {
              ..._billing?.location?.state,
              code: _billing?.location?.state?.code || "-1",
              name: _billing?.location?.state?.name || "",
              countryCode:
                _billing?.location?.state?.countryCode ||
                _billing?.location?.state?.country_code ||
                "US",
            },
          },
        };

        if (shipping) {
          helpers.address.set({
            type: "shipping",
            address: shipping,
          });
        }

        if (billing) {
          helpers.address.set({
            type: "billing",
            address: billing,
          });
        }
      },
      stageGear: async () => {
        const hasStageGear = !!data.data?.nonLicensedItems?.find(
          (item) => item.product.type === "STAGE_GEAR"
        );

        if (hasStageGear) {
          const region = await ShoppingCartMethods.getStageGearRegion();

          helpers.stageGear.set({
            shippingCountries:
              region?.["countries"]?.map((country) => country?.code) || [],
          });
        }
      },
    },
    points: {
      set: async (line: number, points: number) => {
        const cart = await ShoppingCartMethods.applyPoints({
          token,
          line,
          points,
        });

        if (cart?.["hint"]) {
          Toast.error(cart?.["hint"]);
          return;
        }

        dispatch(Slice.set(cart));
      },
    },
  };

  const helpers = {
    helper: ShoppingCartHelpers,
    address: {
      set: ({
        type,
        address,
      }: {
        type: "shipping" | "billing";
        address: Ecommerce.Payload.Address.CreateAddress;
      }) => {
        dispatch(
          Slice.address({
            type,
            address,
          })
        );
      },
    },
    production: {
      set: ({
        type,
        production,
        meta,
      }: {
        type: "licensed" | "non-licensed" | "all";
        production: Ecommerce.Payload.Production.CreateProduction;
        meta?: {
          organization?: string;
        };
      }) => {
        dispatch(
          Slice.production({
            type,
            production,
            meta,
          })
        );
      },
    },
    control: {
      set: ({ type, value }: { type: "same" | "correct"; value: boolean }) => {
        dispatch(Slice.control({ type, value }));
      },
    },
    stageGear: {
      set: ({ shippingCountries }: { shippingCountries: string[] }) => {
        dispatch(Slice.stageGear({ shippingCountries }));
      },
    },
  };

  const utils = {
    exists: ({
      id,
      options,
      position,
    }: {
      id: number | string;
      options?: {
        proLicense?: boolean;
      };
      position?: "licensed" | "non-licensed";
    }) => {
      return ShoppingCartUtils.itemExists({
        cart: data,
        id,
        options,
        position,
      });
    },
    getLineItemId: (id: number | string) => {
      return (
        data?.data?.nonLicensedItems?.find(
          (item) => String(item?.product?.id) === String(id)
        )?.id ||
        data?.data?.licensedItems?.find(
          (item) => String(item?.product?.id) === String(id)
        )?.id
      );
    },
  };

  useEffect(() => {
    if (token && ThrottlerController.can("cart", "reload", 2000)) {
      reload();
    }
  }, [token]);

  return {
    data,
    quantity,
    step,
    methods,
    helpers,
    utils,
  };
};

export default useShoppingCart;
