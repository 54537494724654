import { boolean, mixed, object, string } from "yup";

import { ProductionSchema } from "./productionSchema";
import { ProductSchema } from "./productSchema";
import { SubtotalSchema } from "./subtotalSchema";

export const LicensedItemSchema = object({
  id: mixed(),
  production: ProductionSchema,
  proLicense: boolean().nullable().default(false),
  rentalStart: string().nullable(),
  rentalEnd: string().nullable(),
  subtotal: SubtotalSchema,
  ovationPoints: string().nullable(),
  product: ProductSchema,
  gearBooqableOrderId: string().optional().nullable(),
});
