import { useState, useEffect } from "react";
import { useAuth } from "@with-nx/auth";
import { useService } from "@with-nx/hooks-n-helpers";

export const useBalance = () => {
  const [points, _points] = useState(0);
  const auth = useAuth();
  const token = auth?.authState?.session?.token as string;

  useEffect(() => {
    if (token) {
      const makeRequestToMicroservice = useService("microservice", {
        cache: 60_000,
      });

      (async () => {
        try {
          const request = await makeRequestToMicroservice(
            "GET",
            "/ecommerce/ovation/balance",
            undefined,
            {
              Authorization: `Bearer ${token}`,
            }
          );

          if (request?.["available"]) {
            _points(Number(request["available"]));
          }
        } catch (error) {
          console.error(error);
        }
      })();
    }
  }, [token]);

  return points;
};
