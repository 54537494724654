import "./designed-button.module.css";

import { Tooltip } from "@with-nx/editor-ui";
import { Formatter, useMobile } from "@with-nx/hooks-n-helpers";
import { motion } from "framer-motion";
import { ReactNode, useState } from "react";
import { Box } from "simple-effing-primitive-layout";

import Colors from "../../../../helpers/src/lib/Colors";
import DesignedIcon, {
  AvailableIconName,
} from "../designed-icon/designed-icon";
import Rule from "../rule/rule";

type DesignedButtonTheme = {
  [key: string]: {
    color?: string;
    background?: string;
    border?: string;
    disabled?: {
      color?: string;
      background?: string;
      border?: string;
    };
    hover?: {
      color?: string;
      background?: string;
      border?: string;
    };
  };
};

type DesignedButtonSizes = {
  [key: string]: {
    height?: number;
    padding?: number;
    rule?: string;
    icon?: number;
  };
};

const DarkTheme: DesignedButtonTheme = {
  primary: {
    background: Colors.primary,
    color: Colors.background,
    border: "unset",
    disabled: {
      background: "#AFAFAF",
      color: "#6F6F6F",
      border: "unset",
    },
    hover: {
      background: "#CD9A53",
      color: Colors.background,
      border: "unset",
    },
  },
  secondary: {
    background: Colors.accent,
    color: Colors.font3,
    border: "unset",
    disabled: {
      background: "#2D3542",
      color: "#6F6F6F",
      border: "1px solid #6F6F6F",
    },
  },
  tetriary: {
    background: "transparent",
    color: Colors.primary,
    border: `1px solid ${Colors.primary}`,
    disabled: {
      background: "#2D3542",
      color: "#6F6F6F",
      border: "1px solid #6F6F6F",
    },
    hover: {
      background: "#2D3542",
      color: Colors.primary,
      border: `1px solid ${Colors.primary}`,
    },
  },
  "tetriary-white": {
    background: "transparent",
    color: "white",
    border: `1px solid white`,
  },
  "tetriary-white-translucent": {
    background: "rgba(255,255,255,0.1)",
    color: "white",
    border: `1px solid white`,
    hover: {
      background: Colors.font1,
      color: Colors.background,
      border: "unset",
    },
  },
  ghost: {
    background: "rgba(255,255,255,0.1)",
    color: "rgba(255,255,255,0.5)",
    border: `1px solid rgba(255,255,255,0.1)`,
  },
  "tetriary-accent": {
    background: "transparent",
    color: "white",
    border: `1px solid ${Colors.accent}`,
  },
  link: {
    background: "transparent",
    color: Colors.primary,
    border: `unset`,
    disabled: {
      background: "transparent",
      color: "#909090",
      border: "unset",
    },
    hover: {
      background: "#14181D",
      color: Colors.primary,
      border: `unset`,
    },
  },
  white: {
    background: Colors.font1,
    color: Colors.background,
    border: "unset",
  },
  positive: {
    background: Colors.positive,
    color: "#FFFFFF",
    border: "unset",
  },
  warning: {
    background: Colors.warning,
    color: "#FFFFFF",
    border: "unset",
  },
  negative: {
    background: Colors.negative,
    color: "#FFFFFF",
    border: "unset",
  },
  "blue-gradient": {
    background: "linear-gradient(90deg, #46C2F2 0%, #1875FF 106.85%)",
    color: "#FFFFFF",
    border: "unset",
  },
  "tetriary-white-gradient": {
    background:
      "linear-gradient(118deg, rgba(255, 255, 255, 0.20) 0.09%, rgba(255, 255, 255, 0.02) 100.09%)",
    color: "#FFFFFF",
    border: "unset",
  },
};

const LightTheme: DesignedButtonTheme = {
  primary: {
    background: Colors.primary,
    color: Colors.light.background,
    border: "unset",
  },
  secondary: {
    background: Colors.light.accent,
    color: Colors.light.font3,
    border: "unset",
  },
  tetriary: {
    background: "transparent",
    color: Colors.primary,
    border: `1px solid ${Colors.primary}`,
  },
  positive: {
    background: Colors.positive,
    color: "#FFFFFF",
    border: "unset",
  },
  white: {
    background: Colors.font1,
    color: Colors.background,
    border: "unset",
  },
  warning: {
    background: Colors.warning,
    color: "#FFFFFF",
    border: "unset",
  },
  negative: {
    background: Colors.negative,
    color: "#FFFFFF",
    border: "unset",
  },
};

const Sizes: DesignedButtonSizes = {
  large: {
    height: 48,
    padding: 16,
    rule: "ll",
    icon: 24,
  },
  medium: {
    height: 40,
    padding: 10,
    rule: "lm",
    icon: 20,
  },
  small: {
    height: 32,
    padding: 12,
    rule: "ls",
    icon: 18,
  },
  tiny: {
    height: 24,
    padding: 10,
    rule: "lt",
    icon: 16,
  },
  extraSmall: {
    height: 24,
    padding: 12,
    rule: "lt",
    icon: 16,
  },
  extraLarge: {
    height: 48,
    padding: 16,
    rule: "ll",
    icon: 32,
  },
};

const Theme: {
  dark: DesignedButtonTheme;
  light: DesignedButtonTheme;
} = {
  dark: DarkTheme,
  light: LightTheme,
};

const MODE: "dark" | "light" = "dark";

export interface DesignedButtonProps {
  icon?: AvailableIconName | ReactNode;
  label?: string | null | undefined;
  theme?: string;
  press?:
    | ((event?: unknown) => void | Promise<void>)
    | null
    | undefined
    | unknown;
  properties?: {
    [key: string]: unknown;
  };
  container?: {
    [key: string]: unknown;
  };
  disable?: boolean | string[];
  size?: string;
  square?: boolean;
  loading?: boolean;
  color?: string;
  icolor?: string;
  type?: {
    [key: string]: unknown;
  };
  href?: string;
  bypass?: boolean;
  border?: string;
  background?: string;
  configuration?: {
    icon?: {
      size?: number;
    };
  };
}

export const DesignedButton = ({
  icon,
  label = "",
  theme = "primary",
  press,
  properties,
  disable = false,
  size,
  square,
  loading,
  container,
  color,
  icolor,
  type,
  href,
  bypass,
  border,
  background,
  configuration,
}: DesignedButtonProps) => {
  const [hover, _hover] = useState(false);
  const _selected = Theme[MODE][theme] || Theme[MODE]["primary"];
  const _theme =
    hover && disable
      ? Theme[MODE]["secondary"]
      : hover && _selected.hover
      ? _selected.hover
      : disable
      ? _selected.disabled || Theme[MODE]["secondary"]
      : _selected;

  const mobile = useMobile();

  const _size = size
    ? Sizes[mobile && !bypass ? "small" : size] || Sizes["medium"]
    : Sizes["medium"];

  const id = `button-${Formatter.slugify(label || "")}`;

  const Element = () => (
    <Box
      css="-simple-ui-designed-button-transition"
      parse={`h:${_size.height} w:${square ? _size.height : "auto"} pr:${
        square ? 0 : _size.padding
      } pl:${square ? 0 : _size.padding} br:5 d:inline-flex a:center j:${
        square ? "center" : "flex-start"
      }`}
      color={background || _theme.background}
      border={border || _theme.border}
      press={!disable && !loading ? press : undefined}
      element={href ? "a" : "button"}
      native={{
        href: href || undefined,
        background: _theme.background,
        rel: href
          ? href?.includes("http") && !href?.includes("broadwaymedia")
            ? "nofollow"
            : "noreferrer"
          : undefined,
        id,
        cypress: id,
      }}
      style={{
        background: _theme.background,
      }}
      {...properties}
    >
      {loading ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16px"
          height="16px"
          viewBox="0 0 16 16"
          preserveAspectRatio="xMidYMid"
          className="-simple-ui-designed-button-transition"
        >
          <circle
            cx="8"
            cy="8"
            fill="none"
            stroke={color || _theme.color}
            strokeWidth="2"
            r="6"
            strokeDasharray="28.274333882308138 11.42477796076938"
          >
            <animateTransform
              attributeName="transform"
              type="rotate"
              repeatCount="indefinite"
              dur="1s"
              values="0 8 8;360 8 8"
              keyTimes="0;1"
            ></animateTransform>
          </circle>
        </svg>
      ) : (
        <>
          {icon ? (
            typeof icon === "string" ? (
              <DesignedIcon
                color={icolor || color || _theme.color}
                size={configuration?.icon?.size || _size.icon || 20}
                name={icon as AvailableIconName}
              />
            ) : (
              icon
            )
          ) : undefined}
          {label ? (
            <Rule
              style={{ whiteSpace: "nowrap" }}
              left={icon ? 10 : 0}
              rule={_size.rule}
              color={color || _theme.color}
              css="-simple-ui-designed-button-transition"
              {...type}
            >
              {label}
            </Rule>
          ) : undefined}
        </>
      )}
    </Box>
  );

  return (
    <motion.div
      whileHover={{ scale: 1.02, opacity: 0.85 }}
      whileTap={disable ? { rotateZ: 5 } : { scale: 0.98, opacity: 1 }}
      transition={{
        type: "spring",
        damping: 10,
        stiffness: 100,
      }}
      style={{ display: "inline-flex" }}
      onMouseEnter={() => _hover(true)}
      onMouseLeave={() => _hover(false)}
      {...container}
    >
      {disable && typeof disable !== "boolean" ? (
        <Tooltip position="bottom-center" label={disable?.join(", ")}>
          <Element />
        </Tooltip>
      ) : (
        <Element />
      )}
    </motion.div>
  );
};

export default DesignedButton;
