/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  UseEcommerceOrderPaymentMethods,
  useService,
} from "@with-nx/hooks-n-helpers";
import { array, object, string } from "yup";

import { AddressSchema } from "../schemas/addressSchema";
import { ProductionSchema } from "../schemas/productionSchema";
import { ShoppingCartSchema } from "../schemas/shoppingCartSchema";
import { Ecommerce } from "../types/types";

export type ShoppingCartMethodProductType =
  | "SCENIC_PROJECTION_PACKAGE"
  | "DIGITAL_DROP"
  | "CHOREO_GUIDE"
  | "STAGE_GEAR"
  | "AD_HOC";

export class ShoppingCartMethods {
  static async getCurrent({ token }: { token: string }) {
    const service = useService("microservice");

    return await ShoppingCartSchema.validate(
      await service(
        "GET",
        "/ecommerce/shopping-carts",
        {},
        {
          Authorization: "Bearer " + token,
        }
      ),
      {
        strict: false,
      }
    );
  }

  static async getProductions({ token }: { token: string }) {
    const service = useService("microservice");
    return await array()
      .of(ProductionSchema)
      .validate(
        await service(
          "GET",
          "/ecommerce/shopping-carts/productions",
          {},
          {
            Authorization: "Bearer " + token,
          }
        )
      );
  }

  static async addProduct({
    token,
    options,
    id,
    type,
  }: {
    token: string;
    options?: {
      proLicense?: boolean;
      rentalStart?: string;
      rentalEnd?: string;
    };
    id: number | string;
    type: ShoppingCartMethodProductType;
  }) {
    const service = useService("microservice");
    return await ShoppingCartSchema.validate(
      await service(
        "PUT",
        "/ecommerce/shopping-carts/products",
        {
          productId: id,
          type,
          ...options,
        },
        {
          Authorization: "Bearer " + token,
        }
      )
    );
  }

  static async removeProduct({
    token,
    id,
  }: {
    token: string;
    id: number | string;
  }) {
    const service = useService("microservice");
    return await ShoppingCartSchema.validate(
      await service(
        "DELETE",
        `/ecommerce/shopping-carts/products/${id}`,
        {},
        {
          Authorization: "Bearer " + token,
        }
      )
    );
  }

  static async createProduction({
    token,
    production,
    all,
  }: {
    token: string;
    production: Ecommerce.Schemas.ProductionSchemaType;
    all?: boolean;
  }) {
    const service = useService("microservice");

    const temporary = {};

    if (production) {
      Object.entries(production).map(([key, value]) => {
        if (typeof value === "string") {
          if (value?.length > 0) {
            temporary[key] = value;
          }
        } else {
          temporary[key] = value;
        }
      });
    }

    return await ShoppingCartSchema.validate(
      await service(
        "PUT",
        `/ecommerce/shopping-carts/productions`,
        {
          ...temporary,
          additionalWeeks: production?.additionalWeeks || 0,
          attachToAllItems: Boolean(all),
        },
        {
          Authorization: "Bearer " + token,
        }
      )
    );
  }

  static async updateProduction({
    token,
    production,
  }: {
    token: string;
    production: Ecommerce.Schemas.ProductionSchemaType;
  }) {
    return await this.createProduction({ token, production });
  }

  static async setProductionToItem({
    token,
    production,
    id,
  }: {
    token: string;
    production: number;
    id: string | number;
  }) {
    const service = useService("microservice");
    return await ShoppingCartSchema.validate(
      await service(
        "PATCH",
        `/ecommerce/shopping-carts/line-item/${id}/production`,
        {
          productionId: production,
        },
        {
          Authorization: "Bearer " + token,
        }
      )
    );
  }

  static async deleteProduction({
    token,
    production,
  }: {
    token: string;
    production: number | string;
  }) {
    const service = useService("microservice");
    return await ShoppingCartSchema.validate(
      await service(
        "DELETE",
        `/ecommerce/shopping-carts/productions/${production}`,
        {},
        {
          Authorization: "Bearer " + token,
        }
      )
    );
  }

  static async setAddress({
    token,
    address,
    type,
  }: {
    token: string;
    address: number | string;
    type: "BILLING" | "SHIPPING" | "BOTH";
  }) {
    const service = useService("microservice");
    return await service(
      "PATCH",
      `/ecommerce/shopping-carts/address/${type}`,
      {
        addressId: address,
      },
      {
        Authorization: "Bearer " + token,
      }
    );
  }

  static async markInvoiceAsDownloaded({ token }: { token: string }) {
    const service = useService("microservice");
    return await service(
      "PATCH",
      `/ecommerce/shopping-carts/downloaded-invoice`,
      {},
      {
        Authorization: "Bearer " + token,
      }
    );
  }

  static async applyPromotionalDiscount({
    token,
    code,
  }: {
    token: string;
    code: string;
  }) {
    const service = useService("microservice");
    return await ShoppingCartSchema.validate(
      await service(
        "PATCH",
        `/ecommerce/shopping-carts/apply-promotional-discount`,
        {
          code,
        },
        {
          Authorization: "Bearer " + token,
        }
      )
    );
  }

  static async applyPoints({
    token,
    points,
    line,
  }: {
    token: string;
    points: number;
    line: number;
  }) {
    const service = useService("microservice", {
      bypass: true,
    });

    const result = await service(
      "PATCH",
      `/ecommerce/shopping-carts/line-item/${line}/ovation-points`,
      {
        points,
      },
      {
        Authorization: "Bearer " + token,
      }
    );

    return await ShoppingCartSchema.validate(result);
  }

  static async removePromotionalDiscount({
    token,
    code,
  }: {
    token: string;
    code: string;
  }) {
    const service = useService("microservice");
    return await ShoppingCartSchema.validate(
      await service(
        "PATCH",
        `/ecommerce/shopping-carts/remove-promotional-discount`,
        {
          code,
        },
        {
          Authorization: "Bearer " + token,
        }
      )
    );
  }

  static async createAddress({
    token,
    address,
  }: {
    token: string;
    address: Ecommerce.Payload.Address.CreateAddress;
  }) {
    const service = useService("microservice");
    return await object({
      id: string(),
    }).validate(
      await service(
        "POST",
        `/backstage/customer/address`,
        {
          ...address,
          line2: address.line2 || null,
          defaultBilling: address?.defaultBilling || false,
          defaultShipping: address?.defaultShipping || false,
        },
        {
          Authorization: "Bearer " + token,
        }
      )
    );
  }
  static async updateAddress({
    token,
    address,
    id,
  }: {
    token: string;
    address: Ecommerce.Payload.Address.CreateAddress;
    id: string | number;
  }) {
    const service = useService("microservice");
    await service(
      "PUT",
      `/backstage/customer/address/${id}`,
      {
        ...address,
      },
      {
        Authorization: "Bearer " + token,
      }
    );
  }
  static async getAllAddresses({ token }: { token: string }) {
    const service = useService("microservice");
    return await array()
      .of(AddressSchema)
      .validate(
        await service(
          "GET",
          `/backstage/customer/address`,
          {},
          {
            Authorization: "Bearer " + token,
          }
        )
      );
  }

  static async checkoutCreditCard({
    cart,
    token,
  }: {
    cart: number | string;
    token: string;
  }) {
    const service = useService("microservice");
    return (await service(
      "POST",
      `/ecommerce/orders/checkout`,
      {
        shoppingCartId: cart,
        paymentMethod: "CREDIT_CARD",
      },
      {
        Authorization: "Bearer " + token,
      }
    )) as any;
  }

  static async checkoutPurchaseOrder({
    cart,
    token,
    blob,
  }: {
    cart: number | string;
    token: string;
    blob: string;
  }) {
    const service = useService("microservice");
    return (await service(
      "POST",
      `/ecommerce/orders/checkout`,
      {
        shoppingCartId: cart,
        paymentMethod: "PURCHASE_ORDER",
        purchaseOrderBlobId: blob,
      },
      {
        Authorization: "Bearer " + token,
      }
    )) as any;
  }

  static async checkoutRoyalties({
    cart,
    token,
  }: {
    cart: number | string;
    token: string;
  }) {
    const service = useService("microservice");
    return (await service(
      "POST",
      `/ecommerce/orders/checkout`,
      {
        shoppingCartId: cart,
        paymentMethod: "ROYALTIES_ONLY",
      },
      {
        Authorization: "Bearer " + token,
      }
    )) as any;
  }

  static async registerProposalView({ proposalId, token }) {
    const service = useService("microservice");
    try {
      service(
        "POST",
        `/ecommerce/proposals/${proposalId}/view`,
        {},
        {
          Authorization: "Bearer " + token,
        }
      );
    } catch (error) {
      console.error(error);
    }
  }

  static async setPaymentMethod({
    orderId,
    paymentMethod,
    token,
    poBlobId,
  }: {
    orderId: string | number;
    token: string;
    paymentMethod: UseEcommerceOrderPaymentMethods;
    poBlobId?: string;
  }) {
    const service = useService("microservice");

    return await service(
      "PATCH",
      `/ecommerce/orders/${orderId}/payment-method`,
      {
        method: paymentMethod,
        ...(poBlobId ? { poBlobId } : {}),
      },
      {
        Authorization: "Bearer " + token,
      }
    );
  }

  static async getStageGearRegion() {
    try {
      const service = useService("microservice", {
        cache: 600_000,
      });

      const pricingTable = await service("GET", "/ecommerce/price-tables", {
        "pagination[page]": "1",
        "pagination[pageSize]": "50",
        "filter[type]": "STAGE_GEAR",
      });

      if (pricingTable) {
        const regionId = pricingTable["result"]?.[0].region?.id;
        const region = await service("GET", `/ecommerce/regions/${regionId}`);

        return region;
      }

      return undefined;
    } catch (error) {
      console.error("error", error);
      return undefined;
    }
  }
}

export default ShoppingCartMethods;
