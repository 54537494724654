import { useState, useEffect } from "react";
import { useAuth } from "@with-nx/auth";
import { useService } from "@with-nx/hooks-n-helpers";

export const useChangelog = () => {
  const [changelogs, _changelogs] = useState<
    {
      amount?: string;
      createdAt?: string;
      order?: {
        id?: number;
      };
      ovationPointsChangelogs?: {
        points?: {
          expiresAt?: string;
        };
      }[];
    }[]
  >([]);
  const auth = useAuth();
  const token = auth?.authState?.session?.token as string;

  useEffect(() => {
    if (token) {
      const makeRequestToMicroservice = useService("microservice");

      (async () => {
        try {
          const request = await makeRequestToMicroservice(
            "GET",
            "/ecommerce/ovation/changelogs",
            {
              "pagination[page]": 1,
              "pagination[size]": 25,
              "includes[0]": "ovationPointsChangelogs",
              "includes[1]": "ovationPointsChangelogs.points",
            },
            {
              Authorization: `Bearer ${token}`,
            }
          );

          if (request?.["result"]) {
            _changelogs(request["result"]);
          }
        } catch (error) {
          console.error(error);
        }
      })();
    }
  }, [token]);

  return changelogs;
};
