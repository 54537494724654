import { boolean, object, string } from "yup";

export const SubtotalSchema = object({
  products: string(),
  taxInclusive: string(),
  taxNonInclusive: string(),
  freight: object({
    available: boolean(),
    rushFee: boolean(),
    expeditedFee: boolean(),
  }),
  discount: string(),
  ovationDeduction: string(),
  discountType: string().nullable(),
  discountCode: string().nullable(),
  subtotal: string(),
});
