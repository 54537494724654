/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * @todo
 * Change projectors product hook to use the new URL.
 */
import { useServerRegion } from "@with-nx/region";
import { useEffect, useState } from "react";

import Currency from "./Currency";
import useService from "./useService";

type ProductPricingGeneral = {
  id?: number | string;
  enabled: boolean;
  taxInclusive: boolean;
  price: string;
  redemption?: number;
  freight: string;
  tax: string;
  region: {
    id: number;
  };
  rentDays: number;
  pricing?: {
    price: string;
    freight: string;
    tax: string;
    beforeTax: string;
    afterTax: string;
  };
  priceProLicense?: string;
  redemptionProLicense?: number;
  taxProLicense?: string;
  licensors?: PricingLicensor[];
  product?: {
    id: number;
  };
};

type PricingProductTypes =
  | "SCENIC_PROJECTION_PACKAGE"
  | "CHOREO_GUIDE"
  | "ACCESSORY"
  | "PROJECTOR"
  | "DIGITAL_DROP"
  | "STAGE_GEAR";

type ProductPricingVariations =
  | "JUNIOR"
  | "REGULAR"
  | "EXTENSION_50"
  | "EXTENSION_200"
  | "COMMON_MOUNT"
  | "HEAVY_DUTY_MOUNT"
  | "BASIC"
  | "STANDARD"
  | "PLUS"
  | "PREMIUM"
  | string;

type ScenicProjectionPricing = {
  type: "SCENIC_PROJECTION_PACKAGE";
  variation: "JUNIOR" | "REGULAR" | string;
};

type ChoreoGuidePricing = {
  type: "CHOREO_GUIDE";
  variation: "JUNIOR" | "REGULAR" | string;
};

type AccessoriesPricing = {
  type: "ACCESSORY";
  variation:
    | "EXTENSION_50"
    | "EXTENSION_200"
    | "COMMON_MOUNT"
    | "HEAVY_DUTY_MOUNT"
    | string;
};

type ProjectorPricing = {
  type: "PROJECTOR";
  variation: "BASIC" | "STANDARD" | "PLUS" | "PREMIUM" | string;
};

type DigitalDropPricing = {
  type: "DIGITAL_DROP";
  variation?: string;
};

export type ProductPricing = ProductPricingGeneral &
  (
    | ScenicProjectionPricing
    | ChoreoGuidePricing
    | AccessoriesPricing
    | ProjectorPricing
    | DigitalDropPricing
  );

type ProductSearchType = "SCENIC_PROJECTION_PACKAGE" | "CHOREO_GUIDE";

type ProductSearch = {
  id: number | string;
  title: string;
  public: boolean;
  choreoGuide?: {
    id: number | string;
    product: {
      id: number;
      enabled: boolean;
      type: string;
      variation: string;
      licensors?: PricingLicensor[];
    };
  };
  scenicProjections?: {
    id: number | string;
    product: {
      id: number;
      enabled: boolean;
      type: string;
      variation: string;
      licensors?: PricingLicensor[];
    };
  }[];
};

type PricingRegion = {
  id: number;
  name: string;
  enabled: boolean;
  currency: {
    name: string;
    symbol: string;
  };
};

type PricingLicensor = {
  region: unknown;
  licensor: {
    id: number;
    name: string;
    charge: boolean;
    email: string;
    abbreviation: string;
  };
};

export const usePricingRegions = () => {
  const makeReguestToMicroservice = useService("microservice", {
    cache: 1_800_000,
  });
  const [regions, setRegions] = useState<PricingRegion[]>([
    {
      id: 1,
      name: "United States",
      enabled: true,
      currency: {
        name: "United States Dollar",
        symbol: "$",
      },
    },
  ]);

  useEffect(() => {
    try {
      (async () => {
        if (sessionStorage.getItem("regions")) {
          setRegions(JSON.parse(sessionStorage.getItem("regions") || "[]"));
          return;
        }

        const regions = await makeReguestToMicroservice(
          "GET",
          "/ecommerce/regions",
          {
            "pagination[page]": "1",
            "pagination[pageSize]": "50",
          }
        );

        if (regions["result"]) {
          setRegions(regions["result"]);
          sessionStorage.setItem("regions", JSON.stringify(regions["result"]));
        }
      })();
    } catch (e) {
      setRegions([
        {
          id: 1,
          name: "United States",
          enabled: true,
          currency: {
            name: "United States Dollar",
            symbol: "$",
          },
        },
      ]);
    }
  }, []);

  return regions;
};

type UsePricingTableParams = {
  type?: PricingProductTypes;
  variation?: ProductPricingVariations;
  origin?: string;
  region?: number | string;
};

export const usePricingTable = (
  data?: UsePricingTableParams,
  ready?: boolean
) => {
  const makeReguestToMicroservice = useService("microservice", {
    cache: 1_800_000,
  });
  const [pricing, setPricing] = useState<ProductPricing[]>([]);
  const _region = useServerRegion();

  useEffect(() => {
    if (ready === false) {
      return;
    }

    if (!_region) {
      return;
    }

    const rid = data?.region || _region.id?.toString() || "1";

    (async () => {
      const pricing = await makeReguestToMicroservice(
        "GET",
        "/ecommerce/price-tables",
        {
          "pagination[page]": "1",
          "pagination[pageSize]": "100",
          "filter[region_id]": rid,
          ...(data?.type && { "filter[type]": data.type }),
          ...(data?.variation && { "filter[variation]": data.variation }),
        }
      );

      if (pricing["result"]) {
        setPricing(
          pricing["result"]?.filter(
            (i: any) => String(i?.region?.id) === String(rid)
          )
        );
      }
    })();
  }, [ready, _region?.id]);

  return pricing;
};

export const usePricing = (
  {
    type,
    variation,
    region,
  }: {
    type: PricingProductTypes;
    variation?: ProductPricingVariations;
    region?: string | number;
  },
  ready?: boolean
) => {
  const table = usePricingTable({ type, variation }, ready);

  const pricing = table.find((item: ProductPricing) => {
    if (item.type === type) {
      if (
        variation &&
        item?.variation === variation &&
        String(item?.region?.id) === String(region)
      ) {
        return true;
      }
      if (!variation) {
        return true;
      }
    }
    return false;
  });

  console.log("🪵", "Pricing Table", table, region, pricing);

  return pricing;
};

export const useProjectorProduct = ({
  type,
  variation,
}: {
  type?: string;
  variation?: string;
}) => {
  const [product, _product] = useState<
    | {
        id: number;
        enabled: boolean;
        type: string;
        variation: string;
        rentDays?: number;
      }
    | undefined
  >(undefined);

  const region = useServerRegion();
  const _table = usePricingTable({
    type: (type || "PROJECTOR") as PricingProductTypes,
    variation,
  });

  useEffect(() => {
    const makeReguestToMicroservice = useService("microservice", {
      cache: 1_800_000,
    });

    if (!type || !region?.id) {
      return;
    }

    (async () => {
      const request = (await makeReguestToMicroservice(
        "GET",
        `/ecommerce/products/${region.id}/hardware`
      )) as any;

      if (request) {
        const p = request.find((item: any) => {
          if (item.type === type) {
            if (variation && item.variation === variation) {
              return true;
            }
            if (!variation) {
              return true;
            }
          }
          return false;
        });

        _product(p);
      }
    })();
  }, [type, variation, region]);

  const table = _table?.find((item: ProductPricing) => {
    if (item.type === type) {
      if (variation && (item as unknown as any)?.variation === variation) {
        return true;
      }
      if (!variation) {
        return true;
      }
    }
    return false;
  });

  return { pricing: product, table };
};

export type UseHardwareProduct = {
  id: number;
  enabled: boolean;
  type: string;
  variation: string;
};

export const useHardware = () => {
  const [products, _products] = useState<UseHardwareProduct[]>([]);

  const region = useServerRegion();

  useEffect(() => {
    const makeReguestToMicroservice = useService("microservice", {
      cache: 1_800_000,
    });

    if (!region?.id) {
      return;
    }

    (async () => {
      const request = (await makeReguestToMicroservice(
        "GET",
        `/ecommerce/products/${region.id}/hardware`
      )) as any;

      if (request) {
        _products(request);
      }
    })();
  }, [region]);

  return products;
};

export const useSearchPricing = (
  {
    type,
    search,
    scenicProjectionId,
    choreoGuideId,
    region,
  }: {
    type: ProductSearchType;
    search?: string;
    scenicProjectionId?: number | string;
    choreoGuideId?: number | string;
    region?: number | string;
  },
  ready?: boolean
) => {
  const makeReguestToMicroservice = useService("microservice", {
    cache: 1_800_000,
  });
  const table = usePricingTable({
    type,
  });
  const regions = usePricingRegions();
  const [pricing, setPricing] = useState<ProductPricing[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (ready === false) {
      return;
    }

    if (table.length === 0 || !search || regions.length === 0) {
      return;
    }

    const products: ProductSearch[] = [];
    const prices: ProductPricing[] = [];
    const _region: PricingRegion = regions.find(
      (item) => item.id === Number(region)
    ) || {
      id: 1,
      name: "United States",
      enabled: true,
      currency: {
        name: "United States Dollar",
        symbol: "$",
      },
    };

    (async () => {
      const searchProducts = await makeReguestToMicroservice(
        "GET",
        `/ecommerce/products/search/${region || "1"}/${
          scenicProjectionId && choreoGuideId
            ? "global"
            : scenicProjectionId
            ? "scenic-projections"
            : choreoGuideId
            ? "choreo-guides"
            : "global"
        }`,
        {
          "pagination[page]": "1",
          "pagination[pageSize]": "20",
          "filter[searchText]": search,
          "includes[description]": "true",
        }
      );

      if (searchProducts["result"]) {
        searchProducts["result"].map((item: ProductSearch) => {
          if (scenicProjectionId && !item.scenicProjections) {
            return;
          }
          if (choreoGuideId && !item.choreoGuide) {
            return;
          }

          if (choreoGuideId && item.choreoGuide?.id !== choreoGuideId) {
            return;
          }

          if (scenicProjectionId && !item.scenicProjections) {
            return;
          }

          if (
            scenicProjectionId &&
            !item?.scenicProjections?.find(
              (item) => item.id.toString() === scenicProjectionId?.toString()
            )
          ) {
            return;
          }

          if (scenicProjectionId) {
            products.push({
              ...item,
              scenicProjections: item.scenicProjections?.filter(
                (item) => item.id === scenicProjectionId
              ),
            });
          } else {
            products.push(item);
          }
        });
      }

      products.map((item) => {
        const _pricing = table.find((_item: ProductPricing) => {
          if (
            choreoGuideId &&
            item.choreoGuide &&
            item.choreoGuide.product.type === _item.type
          ) {
            if (item.choreoGuide.product.variation === _item.variation) {
              return true;
            }
          }
          if (
            scenicProjectionId &&
            item.scenicProjections &&
            item.scenicProjections.find(
              (item) => item.product.type === _item.type
            )
          ) {
            if (
              item.scenicProjections.find(
                (item) => item.product.variation === _item.variation
              )
            ) {
              return true;
            }
          }
          return false;
        });

        if (_pricing) {
          prices.push({
            ..._pricing,
            pricing: {
              price: Currency.symbol(
                Number(_pricing.price),
                _region.currency.symbol
              ),
              freight: Currency.symbol(
                Number(_pricing.freight),
                _region.currency.symbol
              ),
              tax: Currency.symbol(
                Number(_pricing.tax),
                _region.currency.symbol
              ),
              beforeTax: Currency.symbol(
                _pricing.taxInclusive
                  ? Number(_pricing.price) - Number(_pricing.tax)
                  : Number(_pricing.price),
                _region.currency.symbol
              ),
              afterTax: Currency.symbol(
                _pricing.taxInclusive
                  ? Number(_pricing.price)
                  : Number(_pricing.price) - Number(_pricing.tax),
                _region.currency.symbol
              ),
            },
            id: scenicProjectionId
              ? item.scenicProjections?.[0].product.id
              : choreoGuideId
              ? item.choreoGuide?.product?.id
              : item.id,
            licensors: scenicProjectionId
              ? item?.scenicProjections?.[0]?.product?.licensors || []
              : choreoGuideId
              ? item?.choreoGuide?.product?.licensors || []
              : [],
          });

          if (_pricing.priceProLicense && _pricing.taxProLicense) {
            prices.push({
              ..._pricing,
              variation: "PROFESSIONAL",
              pricing: {
                price: Currency.symbol(
                  Number(_pricing.priceProLicense),
                  _region.currency.symbol
                ),
                freight: Currency.symbol(
                  Number(_pricing.freight),
                  _region.currency.symbol
                ),
                tax: Currency.symbol(
                  Number(_pricing.taxProLicense),
                  _region.currency.symbol
                ),
                beforeTax: Currency.symbol(
                  _pricing.taxInclusive
                    ? Number(_pricing.priceProLicense) -
                        Number(_pricing.taxProLicense)
                    : Number(_pricing.priceProLicense),
                  _region.currency.symbol
                ),
                afterTax: Currency.symbol(
                  _pricing.taxInclusive
                    ? Number(_pricing.priceProLicense)
                    : Number(_pricing.priceProLicense) -
                        Number(_pricing.taxProLicense),
                  _region.currency.symbol
                ),
              },
              id: scenicProjectionId
                ? item.scenicProjections?.[0].product.id
                : choreoGuideId
                ? item.choreoGuide?.product?.id
                : item.id,
              licensors: scenicProjectionId
                ? item?.scenicProjections?.[0]?.product?.licensors || []
                : choreoGuideId
                ? item?.choreoGuide?.product?.licensors || []
                : [],
            });
          }
        }
      });

      setPricing(prices);
      setLoading(false);
    })();
  }, [table, type, search, scenicProjectionId, choreoGuideId, ready, region]);

  return { pricing, loading };
};
